.booking-payment {
  width: 93%;
  max-width: 768px;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .booking-payment {
    margin-left: auto;
    margin-right: auto;
  }
}
