.cars-gallery {
  margin-top: 2rem;
  .swiper-wrapper {
    margin-bottom: 2rem;
  }
  .cars-gallery-inner {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}
.iscarSwiper {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;
}

