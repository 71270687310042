/* steps.scss */


.booking-steps {
  margin-bottom: 2rem;

  .step i.fas:before {
    @apply text-lg;
    color: var(--main-color);
  }
   .swiper-slide {
    height: 190px;
  }
  h2 {
    margin-bottom: 1rem;
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;

    .step-number {
      font-size: 1.5rem;
      margin-right: 1rem;
      min-width: 80px;
    }

    .step-title {
      font-size: 1rem;
    }

    ul, p {
      font-size: .8rem;
    }

    .booking-summary {
      margin-top: 2rem;
      &__data {
        width: 100%;
        min-width: 300px;

      }
    }
    &__active {
      background-color: var(--main-color);
    }

    &__disabled {
      background-color: var(--color-grey);
    }

  }
  #step1 {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;

  }
  #step2 {
    img {
      max-height: 100px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  #step5 {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  
  .swiperBookingSteps-pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .swiper-pagination-bullet {
      background-color: var(--main-color);
      text-align: center;
      color: #fff;
      width: 25px;
      height: 25px;
    }
  }
  .navigation-buttons {
    button {
      background-color: var(--main-color);
      color: #000;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin: 0.5rem;
      cursor: pointer;
    }
  }
}


@media (min-width: 1024px) {
  .swiperBookingSteps {
    flex-direction: row;
    gap: 1rem;
    .step:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
    .step {
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      padding: 1rem;
      height: 200px;
      border-radius: 0;
      h2 {
        margin-bottom: 0;
      }

      .step-number {
        font-size: 1.5rem;
      }
      .step-title {
        font-size: 1rem;
      }
      .booking-summary {
        margin-top: 0;
        font-size: .8rem;
        &__data {
          row-gap: 10px;
          flex-direction: column;
          .step1__arrow {
            display: none;
          }
        }
      }
    }
    .navigation-buttons {
      max-width: 400px;
    }
    #step2 {
      img {

      }
    }
  }
}
