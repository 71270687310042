
/** Footer **/
footer {
  margin-top: 3rem;
  background-color: var(--gray-color-bg);
  color: var(--gray-color-text);

  h4 {
    line-height: 1.4rem;
    font-weight: bold;
    color: var(--gray-color-text);
  }
  ul {
    li {
      margin-bottom: .75rem;
      color: var(--gray-color-text);
    }
  }
  p {
    color: var(--gray-color-text);
  }
  .footer-logo {

  }
}
