@layer base {
  html {
    font-family: 'Poppins', system-ui, sans-serif;
    body {
      background-color:  var(--gray-color-bg);
      font-size: 17px;
    }
    .main-color {
      color: #ffd008;
    }
  }
}

/** Layout **/
.main {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}
.wp-block-group {
  max-width: 100%;
}
.container {
  max-width: var(--content-max-witdh);
}

/** Headings **/
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;

}

h1 {
  text-transform: uppercase;
}
h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 1.2rem;
}

h3 {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 0;
}

p {
  margin-bottom: 1.5rem;
}
