.home-choice-image {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.features {
  font-size: 1.2rem;

  img {
    width: 40px;
    height: 40px;
  }

  .feature-single {
    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      //letter-spacing: 0.03em;
    }
    p {
      font-size: 1rem;
      margin-top: .4rem;
      color: var(--gray-color-text);
    }
  }

}
