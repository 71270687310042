.offices-page {
  .office {
    background-color: var(--header-background-color);
    position: relative;
    .loading-map-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      color: var(--gray-color-text);
    }
    h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    p {
      font-size: .8rem;
    }
    .office-map-iframe {
      width: 100%;
      height: 400px;
      border: none;
      margin-bottom: 2rem;
    }
    .office-data {
      margin-bottom: 1rem;
    }
    a {
      color: #0c88b4;
    }
  }
}