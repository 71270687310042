.wp-container-1 {
  background-color: var(--gray-color-bg);
}

.iscar-block.services-block {
  margin-top: 4rem;
  padding-top: 5rem!important;
}

@media screen and (min-width: 768px) {
  .iscar-block.services-block {
    margin-top: 0;
    padding-top: 0!important;
  }
}

.hero-home {
  width: 100%;
  position: relative;
  background-color: var(--gray-color-text);
  button {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  }
}

.hero-home-inner.hero-home-buttons {
  padding-bottom: 80px;
}

.hero-home .hero-home-inner {
  max-width: var(--content-max-witdh);
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

  p {
    font-size: 2rem;
  }

  h2 {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  h2:first-of-type {
    margin-bottom: 1rem;
  }
}

@media (min-width: 769px) {
  .hero-home {
    background-color: var(--gray-color-bg);
    background-image: url("../images/mustang-cropped.webp");
  }

  .hero-home-inner.hero-home-buttons {
    padding-bottom: 80px;
  }

  .hero-home .hero-home-inner {
    max-width: var(--content-max-witdh);
    position: relative;
    z-index: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);

    p {
      font-size: 2rem;
    }

    h2 {
      font-size: 4rem;
      line-height: 4.5rem;
    }

    h2:first-of-type {
      margin-top: 3.5rem;
    }
  }


  .hero-home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-size: cover;
    z-index: 0;
  }
}

.hero-bottom {
  top: 0;
}

.iscar-block {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 1rem;
  padding-right: 1rem;
  .block-title {
    h2 {
      margin-bottom: 1rem;
    }
  }
}

.hero-bottom {
  width: 100% ! important;
  margin: 0 0 ! important;
  background-color: var(--gray-color-bg);
  text-align: center;
  padding-bottom: 8rem;
  h2 {
    font-size: 3rem !important;
  }
}
.hero-bottom::before {
  background-image: none;
}

.rent-form-bottom {
  margin: 0;
}

.home-offices h2 {
  line-height: 3rem;
}

@media (min-width: 768px) {
  .block-title {
    h2 {
      margin-bottom: 0;
    }
  }
}

.container.offices {
  .office-image {
    min-height: 200px;
    img {
     min-width: 200px;
    }
  }
  .office-data {
    p {
      font-size: .9rem;
    }
  }
}