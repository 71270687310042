.alert {
  border: 1px solid orangered;
  padding: 2rem;
  font-size: 1.2rem;
  text-align: center;
  @apply bg-red-100 text-red-900 rounded-lg;
}

.alert.alert-success {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #000;
  border: 1px solid #000 !important;
  margin-top: 8rem;
  @apply bg-green-100 rounded-lg;
}